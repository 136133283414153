<template>
  <div class="view-list">
    <div class="header-spacing"></div>

    <div class="container">
      <h4 class="mb-3 mb-md-0">Minha Lista</h4>
    </div>

    <div class="container-fluid py-5">
      <div class="container">
        <action-dispatcher action="user/getMyList">
          <div class="row" v-if="list.length">
            <div class="col-xl-1-5 col-lg-3 col-md-4 col-6" v-for="(item, index) in list" :key="index">
              <carousel-media :item="item" />
            </div>
          </div>

          <div class="text-center py-5 text-secondary small" v-else>
            Nenhum item adicionado à lista
          </div>
        </action-dispatcher>
      </div>
    </div>
  </div>
</template>

<script>
import CarouselMedia from '@/components/CarouselMedia'
import { mapState } from 'vuex'

export default {
  name: 'list',
  components: {
    CarouselMedia
  },
  computed: {
    ...mapState('user', ['myList']),
    list () {
      return this.myList.map(item => item.content)
    }
  }
}
</script>

<style lang="scss" scoped>
.view-list {
  h4 {
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
}
</style>
